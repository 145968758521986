<!--
  For documentation see:
  https://github.com/ben-ng/convert-units
-->

<template>
  <span>
    <slot :value="adaptedValue" name="value">
      <span>{{ adaptedValue }}</span>
    </slot>
    <slot :unit="unit" name="unit" />
  </span>
</template>

<script>
import convert from 'convert-units'

export default {
  name: 'UnitItem',
  props: {
    value: {
      type: Number,
      required: true
    },
    currentUnit: {
      type: String,
      default: 'm'
    },
    unitToImperical: {
      type: String,
      default: 'mi'
    },
    unitToMetric: {
      type: String,
      default: 'km'
    },
    settingsOverride: {
      type: Object
    },
    // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
    numberFormat: {
      type: [Function, Object],
      default: () => {
        // const integerDigits = Math.max(Math.floor(Math.log10(Math.abs(value))), 0) + 1
        return {
          // Only show fractions when there are less than 3 digits
          integerDigits: 1,
          maximumFractionDigits: 1
        }
      }
    }
  },
  computed: {
    adaptedValue() {
      if (!this.value) {
        return 0
      }
      const value = convert(this.value)
        .from(this.currentUnit)
        .to(this.unit === 'mph' ? 'm/h' : this.unit) // Special case for mph to work around lib
      // Handle numberFormat as an object differently than numberFormat as a function
      return this.$n(Number(value.toFixed(this.numberFormat.maximumFractionDigits)))
    },
    unit() {
      if (this.currentUnit.toUpperCase() === 'C') {
        return this.settings.temperature === 'celsius' ? this.unitToMetric : this.unitToImperical
      } else {
        return this.settings.distance === 'metric' ? this.unitToMetric : this.unitToImperical
      }
    },
    settings() {
      const mainStore = useMainStore(this.$pinia)

      if (this.settingsOverride) {
        return this.settingsOverride
      }

      return (
        mainStore.settings ?? { distance: 'metric', temperature: 'celsius', twelveHourClock: false }
      )
    }
  }
}
</script>
